import React from 'react';

const OtherTurotials = () => {
  return (
    <div id="outline-container-orgheadline52" class="outline-3">
      <h3 id="other-tutorials">
        <a id="orgheadline2"></a>
        <span class="section-number-3">4.2</span>
        {' Other Tutorials'}
      </h3>
      <div class="outline-text-3" id="text-other-tutorials">
        <p>
          Coming soon...
        </p>
      </div>
    </div>
  );
}

export default OtherTurotials;